import FalsePositiveIcon from "@mui/icons-material/Cancel";
import CheckboxIcon from "@mui/icons-material/CheckBox";
import PlusIcon from "@mui/icons-material/Edit";
import { Divider } from "@mui/material";
import { RecordContext, useRecordContext, useReference } from "react-admin";
import {
  ActionMenuItem,
  DialogActionMenuItem,
  EditAction,
  IconActionMenu,
} from "../../../layout/actions-menu";
import { useUpdateWithNotification } from "../../../providers/data-hooks/useUpdateWithNotification";
import {
  HardResetAction,
  RequestStatusAction,
} from "../../devices/show/actions/send-config-flags";
import type { Device } from "../../devices/types";
import { SendIncidentToLandlordButton } from "../fields/SendIncidentToLandlordButton";
import { IncidentStatus } from "../types";
import type { Incident } from "../types";
import { EditIncidentDialog } from "./EditIncident";
import { CloseIncidentDialog } from "./SolveIncident";

export const IncidentActionMenu = () => {
  const record = useRecordContext<Incident>();
  const { referenceRecord: device } = useReference<Device>({
    id: record?.deviceId ?? "",
    reference: "devices",
  });

  const [flagFalsePositive] = useUpdateWithNotification(
    "incidents",
    record?.id,
    {
      status: IncidentStatus.CLOSED,
      isFalsePositive: true,
    },
  );

  return (
    <IconActionMenu>
      <DialogActionMenuItem
        label="Edit"
        icon={<PlusIcon />}
        dialog={<EditIncidentDialog />}
      />
      <Divider />
      <DialogActionMenuItem
        label="Close"
        icon={<CheckboxIcon />}
        dialog={<CloseIncidentDialog />}
      />
      <SendIncidentToLandlordButton />
      <Divider />
      <ActionMenuItem
        label="False Positive"
        icon={<FalsePositiveIcon />}
        onClick={() => flagFalsePositive()}
      />
      {device && (
        <RecordContext.Provider value={device}>
          <Divider />
          <EditAction label="Edit Device" />
          <RequestStatusAction />
          <HardResetAction />
        </RecordContext.Provider>
      )}
    </IconActionMenu>
  );
};
