import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Form, SelectInput, TextInput, useRecordContext } from "react-admin";
import { ActionDialog, ActionDialogButton } from "../../../layout/actions-menu";
import { useUpdateWithNotification } from "../../../providers/data-hooks/useUpdateWithNotification";
import { IncidentActor, incidentActorLabels } from "../types";
import type { Incident } from "../types";
import { ActionInput } from "./inputs/ActionInput";
import { InterventionAccountingInput } from "./inputs/InterventionAccountingInput";
import { MaintenanceProviderInput } from "./inputs/MaintenanceProviderInput";
import { PriorityInput } from "./inputs/PriorityInput";

export const EditIncidentDialog = () => {
  const record = useRecordContext<Incident>();
  const [updateIncident] = useUpdateWithNotification(
    "incidents",
    record?.id,
    {},
  );

  return (
    <ActionDialog fullWidth>
      <DialogTitle>Edit Incident</DialogTitle>
      <Form<Incident>
        onSubmit={(data: any) => {
          updateIncident("incidents", {
            id: record?.id,
            data: {
              ...data,
              disabledAutoUpdate: true,
            },
          });
        }}
      >
        <DialogContent>
          <SelectInput
            source="assignedTo"
            label="Assigned To"
            fullWidth
            size="small"
            choices={Object.values(IncidentActor).map((s) => ({
              id: s,
              name: incidentActorLabels[s].label,
            }))}
          />

          <MaintenanceProviderInput source="maintenanceProviderId" />
          <InterventionAccountingInput source="interventionAccounting" />
          <PriorityInput source="priority" />
          <ActionInput />
          <TextInput
            source="comment"
            label="Comment"
            helperText="Can be shared with maintenance provider"
            multiline
            minRows={4}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <ActionDialogButton>Cancel</ActionDialogButton>
          <ActionDialogButton variant="contained" type="submit">
            Submit
          </ActionDialogButton>
        </DialogActions>
      </Form>
    </ActionDialog>
  );
};
