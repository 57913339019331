import FalsePositiveIcon from "@mui/icons-material/Cancel";
import CheckboxIcon from "@mui/icons-material/CheckBox";
import { Divider } from "@mui/material";
import { useRecordContext } from "react-admin";
import {
  ActionMenuItem,
  DialogActionMenuItem,
  IconActionMenu,
} from "../../../layout/actions-menu";
import { useUpdateWithNotification } from "../../../providers/data-hooks/useUpdateWithNotification";
import { CloseIncidentDialog } from "../edit/SolveIncident";
import { IncidentStatus } from "../types";
import type { Incident } from "../types";
import { SendIncidentToLandlordButton } from "./SendIncidentToLandlordButton";

export const ResolveIncidentActionsMenu = () => {
  const record = useRecordContext<Incident>();

  const [flagFalsePositive] = useUpdateWithNotification(
    "incidents",
    record?.id,
    {
      status: IncidentStatus.CLOSED,
      isFalsePositive: true,
    },
  );

  return (
    <IconActionMenu>
      <DialogActionMenuItem
        label="Solve"
        icon={<CheckboxIcon />}
        dialog={<CloseIncidentDialog />}
      />
      <Divider />
      <ActionMenuItem
        label="False Positive"
        icon={<FalsePositiveIcon />}
        onClick={() => flagFalsePositive()}
      />
      <Divider />
      <SendIncidentToLandlordButton />
    </IconActionMenu>
  );
};
